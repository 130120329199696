import React from "react";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import { ratingToDescription } from "../utils/helpers";

const CasinoHeroSection = ({
  casinoName,
  rating,
  shortDescription,
  referralUrl,
  logo,
  logoBackgroundColor,
  bonusShoutout,
}) => {
  const image = getImage(logo);
  const rateWord = ratingToDescription(rating);

  return (
    <div
      className="casino-introduction casino-section casino-hero-wrapper"
      id="casino-introduction--block_62126810141f0"
    >
      <div className="casino-content-wrapper">
        <h1 className="casino-introduction__title">{casinoName}</h1>
        <div className="casino-introduction__info">
          <div className="casino-introduction__short-description">{shortDescription}</div>

          <div className="casino-info-wrapper">
            <a
              href={referralUrl}
              className="btn btn--play-now btn-primary"
              target="_blank"
              rel="noopener sponsored noreferrer"
              data-list="DZADARATBEBOBABRCACLCOCZECEGFIDEHUISINIEJPKZLILVLTLUMTMXMCMANLNZNOPYPEQARSSKSICHAEUYUZ"
            >
              Pelaa nyt
            </a>
            <div className="casino-introduction__bonus-shoutout">{bonusShoutout}</div>
            <div className="hero-casino-rating-wrapper">
              <span>{rateWord}</span>
              <div className="top-casino-rating casino-hero-rating">
                <p>&#9733;</p>
                <span>{rating}/10</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="casino-introduction__style casino-image-wrapper">
        <div className="casino-introduction__info" style={{ backgroundColor: logoBackgroundColor }}>
          <GatsbyImage
            image={image}
            alt={casinoName}
            imgStyle={{ zIndex: "1", maxWidth: "150px" }}
            imgClassName="casino-introduction__logo wp-post-image"
          />
        </div>
      </div>
    </div>
  );
};

export default CasinoHeroSection;
