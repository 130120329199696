import React, { useState, useEffect } from "react";
import { graphql } from "gatsby";

import Layout from "../components/Layout";

import Seo from "../components/Seo";

import CasinoHeroSection from "../components/CasinoHero";
import CasinoBodySection from "../components/CasinoBody";
import CasinoDetailsSection from "../components/CasinoDetails";
import CasinoSideBar from "../components/CasinoSideBar";
import SimilarArticles from "../components/SimilartArticles";
import FaqComponent from "../components/FaqComponent";
import AuthorBox from "../components/AuthorBox";

const CasinoPage = ({ data }) => {
  const pageData = data?.contentfulCasino;

  const [faqItems, setFaqItems] = useState(undefined);

  const matches = [];
  pageData?.content.childMarkdownRemark.html.replace(/<p><strong>(.*?)<\/strong><\/p>/g, function () {
    // arguments[0] is the entire match
    matches.push(arguments[1]);
  });

  useEffect(() => {
    if (pageData?.faqComponent) {
      setFaqItems(pageData.faqComponent.faqItems);
    }
  }, [pageData?.faqComponent]);

  let reviewSchemaData;
  if (pageData) {
    reviewSchemaData = {
      casinoName: pageData?.casinoName,
      casinoImage: pageData?.logo?.file?.url,
      casinoDescription: matches[0],
      casinoRate: pageData?.rating,
      datePublished: pageData.createdAt,
      dateModified: pageData.updatedAt,
      slug: pageData.slug,
      slugPrefix: pageData.categories.toLowerCase(),
      author: pageData.author,
    };
  }

  let prosConsSchemaData;
  if (pageData) {
    prosConsSchemaData = {
      casinoName: pageData?.casinoName,
      overview: pageData?.overview,
      author: pageData?.author,
    };
  }

  return (
    <Layout>
      <Seo
        title={pageData?.seoComponent?.seoTitle}
        description={pageData?.seoComponent?.seoDescription}
        fagSchemaItems={faqItems}
        reviewSchemaData={reviewSchemaData}
        prosConsSchemaData={prosConsSchemaData}
        url={`https://nettikasinolista.com/${pageData.categories.toLowerCase()}/${pageData?.seoComponent?.seoSlug}/`}
        canonical={`https://nettikasinolista.com/${pageData.categories.toLowerCase()}/${
          pageData?.seoComponent?.seoSlug
        }/`}
      />
      <div className="content container" style={{ minHeight: "700px" }}>
        <div className="row">
          <CasinoSideBar reviewsLength={pageData?.reviews?.length} bonuses={pageData?.bonuses} />
          <article className="casino-article col-xl-10 col-12 casino type-casino status-publish">
            <CasinoHeroSection
              casinoName={pageData?.casinoName}
              rating={pageData?.rating}
              shortDescription={pageData?.shortDescription}
              referralUrl={pageData?.referralUrl}
              logo={pageData?.logo}
              casinoAward={pageData?.casinoAward}
              logoBackgroundColor={pageData?.logoBackgroundColor}
              bonusShoutout={pageData?.bonusShoutout}
            />
            <CasinoBodySection content={pageData?.content} overview={pageData?.overview} />
            <CasinoDetailsSection
              website={pageData?.website}
              referralUrl={pageData?.referralUrl}
              softwares={pageData?.softwares}
              withdrawalLimit={pageData?.withdrawalLimit}
              withdrawalMethods={pageData?.withdrawalMethods}
              depositMethods={pageData?.depositMethods}
              withdrawalTimes={pageData?.withdrawalTimes}
              reviews={pageData?.reviews}
              bonuses={pageData?.bonuses}
              logo={pageData?.logo}
            />
            {pageData?.faqComponent && (
              <FaqComponent faqItems={pageData?.faqComponent?.faqItems} title={pageData?.faqComponent?.title} />
            )}
            {pageData.author ? (
              <AuthorBox image={pageData.author.avatar} authorName={pageData.author.name} withTitle={true} />
            ) : null}
          </article>
          {pageData?.relatedArticles && (
            <SimilarArticles title="Samanlaiset artikkelit" articlesList={pageData?.relatedArticles} />
          )}
        </div>
      </div>
    </Layout>
  );
};

export default CasinoPage;

export const query = graphql`
  query casino($id: String!) {
    contentfulCasino(id: { eq: $id }) {
      id
      createdAt(formatString: "YYYY-MM-DD")
      updatedAt(formatString: "YYYY-MM-DD")
      slug
      categories
      casinoName
      casinoAward
      shortDescription
      bonusShoutout
      seoComponent {
        seoTitle
        seoDescription
        seoSlug
      }
      referralUrl
      rating
      logo {
        file {
          url
        }
        gatsbyImage(width: 120, quality: 100, placeholder: NONE, formats: [AUTO, WEBP])
      }
      logoBackgroundColor
      overview {
        title
        type
      }
      content {
        childMarkdownRemark {
          html
        }
      }
      website
      withdrawalLimit
      softwares
      withdrawalMethods
      depositMethods
      withdrawalTimes {
        withdrawalMethods
        withdrawalTime
      }
      bonuses {
        name
        slug
        description
        code
        referralUrl
        bonusBackgroundColor
        partnersTermsAndConditionalLink
        bonusLogo {
          gatsbyImage(width: 120, quality: 100, placeholder: NONE, formats: [AUTO, WEBP])
        }
      }
      author {
        avatar {
          url
          gatsbyImage(width: 120, quality: 100, placeholder: NONE, formats: [AUTO, WEBP])
          file {
            url
          }
        }
        name
      }
      relatedArticles {
        id
        slug
        createdData(formatString: "DD.MM.YYYY")
        categories
        title
        featuredImage {
          gatsbyImage(width: 300, quality: 100, placeholder: NONE, formats: [AUTO, WEBP])
        }
      }
      faqComponent {
        id
        __typename
        title
        marginBottom
        marginTop
        faqItems {
          faqAnswer {
            faqAnswer
            childMarkdownRemark {
              html
            }
          }
          faqQuestion
        }
      }
      reviews {
        name
        slug
        description
        personName
        country
        date(formatString: "DD.MM.YYYY")
        rating
      }
    }
  }
`;
